(<template>
  <header class="auth-form-title">
    <div class="auth-form-title__title-wrapper">
      <h1 class="auth-form-title__title">{{ titleText.toUpperCase() }}</h1>
    </div>
  </header>
</template>)

<script>
  export default {
    props: {
      titleText: {
        type: String,
        default: ''
      }
    }
  };
</script>

<style scoped>
  .auth-form-title {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    min-height: 60px;
    border-bottom: 1px solid #e9eaef;
  }

  .auth-form-title__title-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 270px;
    padding-bottom: 1px;
  }

  .auth-form-title__title {
    margin-bottom: 3px;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }
</style>
