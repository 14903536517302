(<template>
  <div class="restore-pass">
    <auth-form-title :title-text="titleText" />
    <div class="restore-pass__content-wrapper">
      <restore-pass />
    </div>
  </div>
</template>)

<script>
  import AuthFormTitle from '@/components/auth_components/AuthFormTitle';
  import AuthNewPassForm from '@/components/auth_components/AuthNewPassForm';

  export default {
    components: {
      'auth-form-title': AuthFormTitle,
      'restore-pass': AuthNewPassForm
    },
    computed: {
      titleText() { return this.$gettext('New password'); }
    }
  };
</script>

<style scoped>
  .restore-pass {
    display: block;
    width: 100%;
  }

  .restore-pass__content-wrapper {
    display: block;
    width: 100%;
    max-width: 270px;
    margin: 0 auto;
  }
</style>
